/* eslint-disable no-undef */
import React, { createContext, useContext, useEffect } from 'react'
import {
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut,
  GoogleAuthProvider,
  signInWithPopup,
  createUserWithEmailAndPassword,
  updateProfile,
} from 'firebase/auth'
import { doc, getDoc, serverTimestamp, updateDoc } from 'firebase/firestore'
import { useDispatch } from 'react-redux'
import LogRocket from 'logrocket'
import { trackEvent } from '@intercom/messenger-js-sdk'

import { auth, db } from '../firebase'
import { loadTeacherPermissions } from '../Redux/permissionsSlice'
import { setUserProfile, setUserAuth, clearUser, createNewUserProfile } from '../Redux/userSlice'
import { loadOrganization } from '../Redux/organizationsSlice'
import { startLogRocket } from '../LogrocketInit'

import { cleverAuthURL } from './useClever'
import useChat, { getChatObject } from './useChat'
const AuthContext = createContext()

/**
 *  hook to handle authentication to firebase email login, Google login, Clever, and logout
 * - sets the user profile and auth objects in redux store
 * - Also hadles the loading state of auth
 */
const useAuth = () => useContext(AuthContext)

const useAuthMain = () => {
  const dispatch = useDispatch()
  const { initChat } = useChat()
  const [loading, setLoading] = React.useState(true)
  const [profileExists, setProfileExists] = React.useState(false)

  useEffect(() => {
    onAuthStateChanged(auth, async user => {
      if (user && user.uid) {
        const custom_claims = await getCustomClaims(user)
        const profile = await getProfile(user.uid)

        localStorage.setItem('speakableID', user.uid)
        dispatch(
          setUserAuth({
            uid: user.uid,
            email: user.email,
            displayName: user.displayName,
            custom_claims,
            createdAt: user.metadata.creationTime,
            lastLogin: user.metadata.lastSignInTime,
          }),
        )

        if (profile?.roles?.includes('ADMIN')) {
          dispatch(loadTeacherPermissions({ custom_claims }))
        }

        setLoading(false)
        if (profile && user?.uid) {
          dispatch(setUserProfile(profile))
          const isAdmin = profile?.roles?.includes('ADMIN')
          const chatObj = getChatObject(user, profile, isAdmin)

          // initCIO(chatObj)
          setProfileExists(true)
          if (isAdmin) {
            initChat(chatObj)
            dispatch(loadOrganization(user.email))
          }

          startLogRocket(isAdmin, () => {
            setTimeout(() => {
              initLogrocket(user?.uid, profile)
              LogRocket.getSessionURL(sessionURL => {
                trackEvent('LogRocket', { sessionURL })
              })
            }, 3000)
          })
          // update lastAccess in profile
          const ref = doc(db, 'users', user.uid)

          updateDoc(ref, {
            lastAccess: serverTimestamp(),
          })
        } else if (user?.uid && !profile) {
          dispatch(setUserProfile({ isEmpty: true }))
          setProfileExists(false)
        }
      } else {
        setLoading(false)
        dispatch(setUserProfile({ isEmpty: true }))
        dispatch(setUserAuth({ isEmpty: true }))
        initLogrocket(null, null)
      }
    })
  }, [])

  const getProfile = async uid => {
    setLoading(true)
    const ref = doc(db, 'users', uid)

    const data = await getDoc(ref)

    if (data.exists()) {
      return data.data()
    } else {
      return null
    }
  }
  const loginWithEmail = async (email, password) => {
    try {
      const result = await signInWithEmailAndPassword(auth, email, password)
      const user = result.user
      const custom_claims = await getCustomClaims(user)
      const profile = await getProfile(user.uid)

      dispatch(
        setUserAuth({
          uid: user.uid,
          email: user.email,
          displayName: user.displayName,
          custom_claims,
          createdAt: user.metadata.creationTime,
          lastLogin: user.metadata.lastSignInTime,
        }),
      )
      dispatch(setUserProfile(profile))

      return { result, user, profile }
    } catch (error) {
      return { error: true, message: error.message }
    }
  }
  const createAccountWithEmail = async (email, password, name) => {
    try {
      const result = await createUserWithEmailAndPassword(auth, email, password)

      const user = result.user
      const custom_claims = await getCustomClaims(user)
      const profile = await getProfile(user.uid)

      dispatch(
        setUserAuth({
          uid: user.uid,
          email: user.email,
          displayName: name,
          custom_claims,
          createdAt: user.metadata.creationTime,
          lastLogin: user.metadata.lastSignInTime,
        }),
      )
      dispatch(setUserProfile(profile))

      // we need to add the displayName to the user object
      await updateProfile(result.user, {
        displayName: name,
      })
        .then(() => {
          // Update successful
          console.log('update successful')
        })
        .catch(error => {
          // An error occurred
          console.log(error)
        })

      return result
    } catch (error) {
      console.log(error)

      return {
        error: true,
        message: 'It looks like this email is already in use. Trying logging in instead of creating an account.',
      }
    }
  }
  const loginWithGoogle = async () => {
    try {
      const GoogleProvider = new GoogleAuthProvider()

      await signInWithPopup(auth, GoogleProvider)
    } catch (error) {
      console.log(error)
      // check if the error is because Firebase: Error (auth/popup-closed-by-user)

      if (error.code === 'auth/popup-closed-by-user') {
        alert('You may have an ad blocker enabled. Please disable it and try again.')
        console.log('You may have an ad blocker enabled. Please disable it and try again.')
        throw new Error(error)
      }
      throw new Error(error)
    }
  }
  const loginWithClever = async () => {
    window.open(cleverAuthURL, '_self')
  }
  const logout = async () => {
    try {
      await signOut(auth)
      setProfileExists(false)
      dispatch(clearUser())
    } catch (error) {
      console.log(error)
    }
  }

  const getCustomClaims = async user => {
    const custom_claims = await (await user.getIdTokenResult()).claims

    return custom_claims
  }
  const createProfile = async profile => {
    try {
      const newProfile = await dispatch(createNewUserProfile(profile))

      if (newProfile.success) {
        setProfileExists(true)

        return { success: true, message: 'Profile created successfully' }
      } else {
        return { error: true, message: 'Profile creation failed' }
      }
    } catch (error) {
      console.log(error)
    }
  }

  return {
    logout,
    loginWithEmail,
    loginWithGoogle,
    loginWithClever,
    createProfile,
    createAccountWithEmail,
    getProfile,
    loading,
    profileExists,
  }
}

function AuthProvider({ children }) {
  const auth = useAuthMain()

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>
}

export { useAuth, AuthProvider }

const initLogrocket = (uid, user) => {
  const isTeacher = user?.roles?.includes('ADMIN')
  const emailDomain = user?.email?.split('@')[1]

  LogRocket.identify(uid, {
    name: isTeacher ? user?.displayName : `${emailDomain} Student`,
    email: isTeacher ? user?.email : null,
    userType: user?.roles?.[0] || null,
    schoolId: user?.schoolId || null,
    schoolName: user?.schoolName || null,
  })
}
