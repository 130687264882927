import { useSelector } from 'react-redux'
import { collection, doc, getDoc, getDocs, limit, orderBy, query, updateDoc } from 'firebase/firestore'
import notificationTypes from 'utils/notificationTypes'
import { useQuery, useQueryClient } from '@tanstack/react-query'
import moment from 'moment'

import { db } from '../firebase'
import { myFunctions } from '../firebase'
const useNotifications = () => {
  const queryClient = useQueryClient()
  const { auth, profile } = useSelector(({ user }) => user)

  const { data, totalUnread } = useGetNotifications(auth?.uid)

  const updateNotifications = async (id, updates) => {
    if (!id) return

    const docRef = doc(db, `users/${auth.uid}/notificationsV2/`, id)

    await updateDoc(docRef, updates)
    queryClient.setQueryData(['notifications', auth?.uid], oldData => {
      if (!oldData) return []

      return oldData.map(notif => {
        if (notif.id === id) {
          return { ...notif, ...updates }
        }

        return notif
      })
    })
  }

  const createNotification = async (type, data) => {
    let result // Local variable to store the result of the asynchronous operation

    switch (type) {
      case notificationTypes.NEW_ASSIGNMENT:
        result = await handleAssignNotifPromise({ data, profile, auth })
        break
      case notificationTypes.ASSESSMENT_SUBMITTED:
        result = await createAssessmentSubmissionNotification({
          data,
          profile,
          auth,
        })
        break
      case notificationTypes.ASSESSMENT_SCORED:
        result = await createAssessmentScoredNotification({
          data,
          profile,
          auth,
        })
        break
      default:
        result = null
        break
    }

    queryClient.invalidateQueries(['notifications', auth?.uid])

    return result
  }

  return {
    notifications: data || [],
    totalUnread,
    updateNotifications,
    createNotification,
  }
}

export default useNotifications

const sendNotification = async (sendTo, notification) => {
  try {
    const results = await myFunctions.createNotification({
      sendTo,
      notification: {
        ...notification,
      },
    })

    return results
  } catch (e) {
    throw new Error(e)
  }
}

const handleAssignNotifPromise = async ({ data: assignments, profile, auth }) => {
  if (!assignments?.length) return

  try {
    const notifsPromises = assignments.map(async assignment => {
      const {
        section,
        section: { members },
        ...rest
      } = assignment

      if (!section || !members) throw new Error('Invalid assignment data')
      // dont create notification if the assignment is scheduled
      if (rest?.scheduledTime) return new Error('Assignment is scheduled - no notification sent')
      const data = { section, sendTo: members, assignment: { ...rest } }

      return createNewAssignmentNotification({ data, profile, auth })
    })

    await Promise.all(notifsPromises)

    return {
      success: true,
      message: 'Assignment notifications sent successfully',
    }
  } catch (error) {
    console.error('Error in handleAssignNotifPromise:', error)
    // throw error
  }
}

const createNewAssignmentNotification = async ({ data, profile, auth }) => {
  try {
    const { assignment, sendTo } = data
    const teacherName = profile.displayName || 'Your teacher'
    const dueDate = assignment?.dueDateTimestamp
      ? moment(assignment?.dueDateTimestamp?.toDate()).format('MMM Do')
      : null

    const results = await sendNotification(sendTo, {
      courseId: assignment.courseId,
      type: notificationTypes.NEW_ASSIGNMENT,
      senderName: teacherName,
      link: `${window.origin}/assignment/${assignment.id}`,
      messagePreview: `A new assignment "${assignment.name}" is now available. ${dueDate ? `Due ${dueDate}` : ''}`,
      title: 'New Assignment Available!',
      imageUrl: profile?.image?.url,
    })

    return results
  } catch (e) {
    throw new Error(e)
  }
}

const createAssessmentSubmissionNotification = async ({ data: assignment, profile, auth }) => {
  try {
    const studentName = profile.displayName || 'Your student'

    const results = await sendNotification(assignment?.owners, {
      courseId: assignment.courseId,
      type: notificationTypes.ASSESSMENT_SUBMITTED,
      link: `${window.origin}/a/${assignment.id}?studentId=${auth.uid}`,
      title: `Assessment Submitted!`,
      senderName: studentName,
      messagePreview: `${studentName} has submitted the assessment "${assignment?.name}"`,
      imageUrl: profile?.image?.url,
    })

    // await myFunctions.sendAssessmentSubmittedEmail({
    //   studentName: profile?.displayName,
    //   assessmentTitle: assignment.name,
    //   link: `${window.origin}/g?section=${assignment.courseId}&assignment=${assignment.id}&student=${auth.uid}`,
    //   senderImage: profile?.image?.url,
    //   teacherId: assignment?.owners?.[0],
    // })

    return results
  } catch (e) {
    console.log('sendAssessmentSubmittedEmail', e)
    throw new Error(e)
  }
}

const createAssessmentScoredNotification = async ({ data, profile, auth }) => {
  const { assignment, sendTo } = data

  try {
    const teacherName = profile.displayName || 'Your teacher'

    const title = `${assignment?.isAssessment ? 'Assessment' : 'Assignment'} Reviewed!`
    const messagePreview = `Your ${assignment?.isAssessment ? 'assessment' : 'assignment'} has been reviewed by your teacher. Click to view the feedback.`
    const results = await sendNotification(sendTo, {
      courseId: assignment.courseId,
      type: notificationTypes.ASSESSMENT_SCORED,
      link: `${window.origin}/assignment/${assignment.id}`,
      title: title,
      messagePreview: messagePreview,
      imageUrl: profile?.image?.url,
      senderName: teacherName,
    })

    await myFunctions.sendAssessmentScoredEmail({
      assessmentTitle: assignment.name,
      link: `${window.origin}/assignment/${assignment.id}`,
      senderImage: profile?.image?.url,
      studentId: sendTo?.[0],
      teacherName: profile?.displayName,
    })

    return results
  } catch (e) {
    throw new Error(e)
  }
}

const filterNotifications = (notifications, type) => {
  const now = Date.now()
  const last24Hours = now - 24 * 60 * 60 * 1000

  return notifications.filter(notification => {
    if (type === 'read' && notification.read === false) {
      return false
    } else if (type === 'unread' && notification.read === true) {
      return false
    } else if (type === 'new' && (notification.read === true || notification.createdAt < last24Hours)) {
      return false
    } else {
      return true
    }
  })
}

const getNotifications = async uid => {
  try {
    const q = query(collection(db, `users/${uid}/notificationsV2/`), orderBy('createdAt', 'desc'), limit(40))
    const querySnapshot = await getDocs(q)
    const results = []

    querySnapshot.forEach(doc => {
      results.push({ ...doc.data(), id: doc.id })
    })
    // attach a card if notification.cardId exists
    // otherwise, return the card
    const resultsPromises = results.map(async notification => {
      if (notification?.cardId) {
        const card = await getCard(notification?.cardId)

        return { ...notification, card }
      } else {
        return notification
      }
    })
    const resultsWithCards = await Promise.all(resultsPromises)

    return resultsWithCards
  } catch (e) {
    throw new Error(e)
  }
}

const getCard = async cardId => {
  const docRef = doc(db, 'flashcards', cardId)
  const docSnap = await getDoc(docRef)

  if (docSnap.exists()) {
    return { id: docSnap.id, ...docSnap.data() }
  } else {
    return null
  }
}

const useGetNotifications = uid => {
  const { data, isLoading, isError } = useQuery({
    queryKey: ['notifications', uid],
    queryFn: () => getNotifications(uid),
    enabled: !!uid,
  })

  return {
    data,
    totalUnread: data?.filter(n => !n.read).length,
    isLoading,
    isError,
  }
}
