import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getAuth } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'
import { getStorage } from 'firebase/storage'
import { getFunctions, httpsCallable } from 'firebase/functions'

const firebaseConfig = {
  apiKey: import.meta.env.VITE_FIREBASE_API_KEY,
  // authDomain: import.meta.env.VITE_FIREBASE_AUTH_DOMAIN,
  authDomain: 'app.speakable.io',
  databaseURL: import.meta.env.VITE_FIREBASE_DATABASE_URL,
  projectId: import.meta.env.VITE_FIREBASE_PROJECT_ID,
  storageBucket: import.meta.env.VITE_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: import.meta.env.VITE_FIREBASE_MESSAGING_SENDER_ID,
  appId: import.meta.env.VITE_FIREBASE_APP_ID,
  measurementId: import.meta.env.VITE_FIREBASE_MEASUREMENT_ID,
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)
const analytics = getAnalytics(app)
const auth = getAuth(app)
const db = getFirestore(app)
const storage = getStorage(app)
const functions = getFunctions(app)

export { auth, db, storage, functions, analytics }

export const myFunctions = {
  joinCourse: httpsCallable(functions, 'joinCourseV2'),
  removeFromCourse: httpsCallable(functions, 'removeFromCourse'),
  updateAlgoliaIndex: httpsCallable(functions, 'updateAlgoliaIndex'),
  copySet: httpsCallable(functions, 'copySet'),
  getCourseMembers: httpsCallable(functions, 'getCourseMembers'),
  getCourseData: httpsCallable(functions, 'getCourseData'),
  transferPlaylist: httpsCallable(functions, 'transferPlaylist'),
  sendPlaylistTransferEmail: httpsCallable(functions, 'sendPlaylistTransferEmail'),
  submitAssignmentV2: httpsCallable(functions, 'submitAssignmentV2'),
  updateAssignmentGradebookStatus: httpsCallable(functions, 'updateAssignmentGradebookStatus'),
  storeGoogleAuthToken: httpsCallable(functions, 'storeGoogleAuthToken'),
  submitAssignmentToGoogleClassroomV2: httpsCallable(functions, 'submitAssignmentToGoogleClassroomV2'),
  generateAuthUrl: httpsCallable(functions, 'generateAuthUrl'),
  createNotification: httpsCallable(functions, 'createNotificationV2'),
  createOrganization: httpsCallable(functions, 'createOrganization'),
  sendTeacherFeedbackRequestEmail: httpsCallable(functions, 'sendTeacherFeedbackRequestEmail'),
  sendStudentFeedbackRequestEmail: httpsCallable(functions, 'sendStudentFeedbackRequestEmail'),
  // createStripeCustomerPortal: httpsCallable(functions, 'ext-speakable-io-createPortalLink'), //firestore-stripe-payments-ux8c

  createStripeCustomerPortal: httpsCallable(functions, 'ext-firestore-stripe-payments-ux8c-createPortalLink'), //firestore-stripe-payments-ux8c

  collectAllAssignmentsV2: httpsCallable(functions, 'collectAllAssignmentsV2'),
  handleCleverAuthentication: httpsCallable(functions, 'handleCleverAuthentication'),
  syncCleverSection: httpsCallable(functions, 'syncCleverSection'),
  handleGetCleverSections: httpsCallable(functions, 'handleGetCleverSections'),
  createSingleCleverSection: httpsCallable(functions, 'createSingleCleverSection'),
  reopenAssignment: httpsCallable(functions, 'reopenAssignment'),
  flagCard: httpsCallable(functions, 'flagCard'),
  addUserToIntercomCompany: httpsCallable(functions, 'addUserToIntercomCompany'),
  importGoogleClassroomSections: httpsCallable(functions, 'importGoogleClassroomSectionsV2'),
  syncGoogleClassroomSection: httpsCallable(functions, 'syncGoogleClassroomSectionV2'),
  listTeacherCourses: httpsCallable(functions, 'listTeacherCourses'),
  postNewAssignment: httpsCallable(functions, 'postNewAssignment'),
  listTopics: httpsCallable(functions, 'listTopics'),
  submitAssessment: httpsCallable(functions, 'submitAssessment'),
  sendAssessmentSubmittedEmail: httpsCallable(functions, 'sendAssessmentSubmittedEmail'),
  sendAssessmentScoredEmail: httpsCallable(functions, 'sendAssessmentScoredEmail'),
  finalizeFreeResponseAssessment: httpsCallable(functions, 'finalizeFreeResponseAssessment'),
  transcribeAudio: httpsCallable(functions, 'transcribeAudio'),
  getFreeResponseCompletion: httpsCallable(functions, 'getFreeResponseCompletionV2'),
  syncGradebookDoc: httpsCallable(functions, 'syncGradebookDoc'),
  updateGoogleClassroomAssignmentDetails: httpsCallable(functions, 'updateGoogleClassroomAssignmentDetails'),
  reopenAssessment: httpsCallable(functions, 'reopenAssessment'),
  syncAllScoresToGoogleClassroom: httpsCallable(functions, 'syncAllScoresToGoogleClassroom'),
  convertSafariAudio: httpsCallable(functions, 'convertSafariAudio'),
  getTrendingSets: httpsCallable(functions, 'getTrendingSets'),
  getProPlanCredits: httpsCallable(functions, 'getProPlanCredits'),
  onSetOpened: httpsCallable(functions, 'onSetOpened'),
  consumeProPlanCredit: httpsCallable(functions, 'consumeProPlanCredit'),
  checkStudentPermissions: httpsCallable(functions, 'checkStudentPermissions'),
  consumeInstitutionCredit: httpsCallable(functions, 'consumeInstitutionCredit'),
  getAssessmentCompletion: httpsCallable(functions, 'getAssessmentCompletion'),
  updateCourseAnalytics: httpsCallable(functions, 'updateCourseAnalytics'),
  loadOrganization: httpsCallable(functions, 'loadOrganization'),
  breakSyllables: httpsCallable(functions, 'breakSyllables'),
  postToGoogleClassroom: httpsCallable(functions, 'postToGoogleClassroom'),
  cio_createDistrictObject: httpsCallable(functions, 'cio_createDistrictObject'),
  cio_sendTransactionalEmail: httpsCallable(functions, 'cio_sendTransactionalEmail'),
  makePDF: httpsCallable(functions, 'makePDF'),
  getOrgTeachers: httpsCallable(functions, 'getOrgTeachers'),
  loadOrgTeacherProfile: httpsCallable(functions, 'loadOrgTeacherProfile'),
  loadOrganizationV2: httpsCallable(functions, 'loadOrganizationV2'),
  sendOrgInvite: httpsCallable(functions, 'sendOrgInvite'),
  loadOrgPlans: httpsCallable(functions, 'loadOrgPlans'),
  updateOrgAdminStatus: httpsCallable(functions, 'updateOrgAdminStatus'),
  loadOrgData: httpsCallable(functions, 'loadOrgData'),
  updateOrgData: httpsCallable(functions, 'updateOrgData'),
  removeOrgMembers: httpsCallable(functions, 'removeOrgMembers'),
  addUsersToOrgPlan: httpsCallable(functions, 'addUsersToOrgPlan'),
  createChatCompletion: httpsCallable(functions, 'createChatCompletion'),
  getOrgsForSpaces: httpsCallable(functions, 'getOrgsForSpaces'),
  createSpace: httpsCallable(functions, 'createSpace'),
  listSpaces: httpsCallable(functions, 'listSpaces'),
  getSpace: httpsCallable(functions, 'getSpace'),
  createCheckpoint: httpsCallable(functions, 'createCheckpoint'),
  acceptSpaceInvitation: httpsCallable(functions, 'acceptSpaceInvitation'),
  inviteMembersToSpace: httpsCallable(functions, 'inviteMembersToSpace'),
  getSpaceMembers: httpsCallable(functions, 'getSpaceMembers'),
  changeMemberRole: httpsCallable(functions, 'changeMemberRole'),
  deleteMember: httpsCallable(functions, 'deleteMember'),
  addActivity: httpsCallable(functions, 'addActivity'),
  createFolder: httpsCallable(functions, 'createFolder'),
  listFolders: httpsCallable(functions, 'listFolders'),
  deleteFolders: httpsCallable(functions, 'deleteFolders'),
  updateFolder: httpsCallable(functions, 'updateFolder'),
  listActivities: httpsCallable(functions, 'listActivities'),
  getFoldersByIds: httpsCallable(functions, 'getFoldersByIds'),
  deleteActivitiesFromFolder: httpsCallable(functions, 'deleteActivitiesFromFolder'),
  deleteActivity: httpsCallable(functions, 'deleteActivity'),
  addActivitiesToFolders: httpsCallable(functions, 'addActivitiesToFolders'),
  updateSpaceDetails: httpsCallable(functions, 'updateSpaceDetails'),
  deleteSpace: httpsCallable(functions, 'deleteSpace'),
  requestToJoinSpace: httpsCallable(functions, 'requestToJoinSpace'),
  acceptJoinRequest: httpsCallable(functions, 'acceptJoinRequest'),
  declineJoinRequest: httpsCallable(functions, 'declineJoinRequest'),
  leaveSpace: httpsCallable(functions, 'leaveSpace'),
  declineSpaceInvitation: httpsCallable(functions, 'declineSpaceInvitation'),
  copySpace: httpsCallable(functions, 'copySpace'),
  // Comments
  createComment: httpsCallable(functions, 'createComment'),
  getComments: httpsCallable(functions, 'getComments'),
  deleteComment: httpsCallable(functions, 'deleteComment'),
  updateComment: httpsCallable(functions, 'updateComment'),
  getCommentsByAssignmentId: httpsCallable(functions, 'getCommentsByAssignmentId'),
  getCommentsByCardForStudents: httpsCallable(functions, 'getCommentsByCardForStudents'),
  getCommentsByStudentForCards: httpsCallable(functions, 'getCommentsByStudentForCards'),
  // AI Assistant
  submitToolOutputs: httpsCallable(functions, 'submitToolOutputs'),
  retrieveAssistant: httpsCallable(functions, 'retrieveAssistant'),
  createAssistant: httpsCallable(functions, 'createAssistant'),
  createThread: httpsCallable(functions, 'createThread'),
  retrieveThread: httpsCallable(functions, 'retrieveThread'),
  listMessages: httpsCallable(functions, 'listMessages'),
  getRunStatus: httpsCallable(functions, 'getRunStatus'),
  initiateRun: httpsCallable(functions, 'initiateRun'),
  sendMessage: httpsCallable(functions, 'sendMessage'),
  sendMessageAndInitiateRun: httpsCallable(functions, 'sendMessageAndInitiateRun'),
  uploadFile: httpsCallable(functions, 'uploadFile'),
  cancelRun: httpsCallable(functions, 'cancelRun'),
  // Capsule
  updateCapsuleParty: httpsCallable(functions, 'updateCapsuleParty'),
  createCapsuleParty: httpsCallable(functions, 'createCapsuleParty'),
  getCapsulePartyByUserId: httpsCallable(functions, 'getCapsulePartyByUserId'),
  // Set
  updateSet: httpsCallable(functions, 'updateSet'),
}
