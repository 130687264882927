import { useMemo, useState, useEffect } from 'react'
import { isMobile, isTablet, browserName, browserVersion, osName, osVersion, deviceDetect } from 'react-device-detect'

function useDeviceInfo() {
  const [batteryLevel, setBatteryLevel] = useState(null)
  const [hasTouchScreen, setHasTouchScreen] = useState(false)

  // Handle Battery Information
  useEffect(() => {
    let battery = null

    const handleBattery = battery => {
      setBatteryLevel(Math.round(battery.level * 100))
      battery.addEventListener('levelchange', () => {
        setBatteryLevel(Math.round(battery.level * 100))
      })
    }

    if ('getBattery' in navigator) {
      navigator
        .getBattery()
        .then(handleBattery)
        .catch(error => {
          console.error('Error accessing battery information:', error)
          setBatteryLevel('Unavailable')
        })
    } else {
      setBatteryLevel('Unavailable')
    }

    return () => {
      if (battery) {
        battery.removeEventListener('levelchange', () => {})
      }
    }
  }, [])


  // Handle Touch Screen Detection
  useEffect(() => {
    const hasTouch = 'ontouchstart' in window || navigator.maxTouchPoints > 0

    setHasTouchScreen(hasTouch)
  }, [])

  const deviceInfo = useMemo(() => {
    // Extract brand and model if available
    const brand = deviceDetect?.brand || 'Unknown'
    const model = deviceDetect?.model || 'Unknown'

    // Access network information if available
    const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection
    const connectionType = connection?.type || 'unknown'
    const effectiveType = connection?.effectiveType || 'unknown'

    // Determine device type
    const deviceType = isMobile ? 'Mobile' : isTablet ? 'Tablet' : 'Desktop'

    return {
      deviceType,
      brand,
      model,
      browserName: browserName || 'Unknown',
      browserVersion: browserVersion || 'Unknown',
      os: osName || 'Unknown',
      osVersion: osVersion || 'Unknown',
      screenResolution: `${window.screen.width}x${window.screen.height}`,
      language: navigator.language || navigator.userLanguage || 'Unknown',
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || 'Unknown',
      userAgent: navigator.userAgent || 'Unknown',
      connectionType,
      effectiveType,
      batteryLevel,
      hasTouchScreen,
    }
  }, [batteryLevel, hasTouchScreen])

  return deviceInfo
}

export default useDeviceInfo
