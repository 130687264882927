import { createContext, useContext } from 'react'
import { useLocation } from 'react-router-dom'

import useSessionTracking from '@/hooks/useSessionTracking'

const SessionTrackingContext = createContext({})

function SessionTrackingProvider({ userId, children }) {
  const location = useLocation()
  const isAssignmentPage = location.pathname.startsWith('/assignment/')
  const assignmentId = isAssignmentPage ? location.pathname.split('/')[2] : null
  const { endSession } = useSessionTracking({ userId, assignmentId, page: location.pathname })

  const value = {
    endSession,
  }

  return <SessionTrackingContext.Provider value={value}>{children}</SessionTrackingContext.Provider>
}

export default SessionTrackingProvider

export function useSessionTrackingContext() {
  return useContext(SessionTrackingContext)
}
