/* eslint-disable react-hooks/exhaustive-deps */
import { useRef, useEffect } from 'react'
import { useIdleTimer } from 'react-idle-timer'
import { doc, collection, setDoc, runTransaction, getDoc, Timestamp, increment, updateDoc } from 'firebase/firestore'

import { db } from '@/firebase'
import useDeviceInfo from '@/hooks/useDeviceInfo'

const IDLE_TIMEOUT_SECONDS = 10 * 60 // 10 minutes

export const EXCLUDED_PAGES = ['/auth/login', '/auth/create-account']

export const sessionBroadcast = typeof window !== 'undefined' ? new BroadcastChannel('session-management') : null

export default function useSessionTracking({ userId, assignmentId, page }) {
  const startTimeRef = useRef(null)
  const sessionDocRef = useRef(null)
  const intervalRef = useRef(null)
  const isSyncingRef = useRef(false)
  const previousPageRef = useRef(page)
  const pageStartTimeRef = useRef(Date.now())
  const deviceInfo = useDeviceInfo()

  // Generate a unique tab ID for debugging; sessionStorage is per tab
  useEffect(() => {
    if (!sessionStorage.getItem('tabSessionId')) {
      sessionStorage.setItem('tabSessionId', crypto.randomUUID())
    }
  }, [])

  const getLocalStorageKey = () => `session_end`

  const isPageExcluded = () => EXCLUDED_PAGES.includes(page)

  const createSession = async () => {
    if (!userId || isPageExcluded()) return

    const sessionRef = doc(collection(db, 'users', userId, 'sessions'))
    const startTime = Timestamp.now()

    sessionDocRef.current = sessionRef

    try {
      await setDoc(sessionRef, {
        startTime: startTime,
        endTime: null,
        totalTime: 0,
        ...(assignmentId && { assignmentId }),
        page,
        pageViews: [
          {
            page,
            startTime: startTime,
            endTime: null,
            timeSpent: 0,
          },
        ],
        deviceInfo,
      })
      startTimeRef.current = Date.now()

      // Save session ID to sessionStorage
      sessionStorage.setItem('sessionDocRef', sessionRef.id)

      // Start the heartbeat interval
      startHeartbeat()
    } catch (error) {
      console.error('Error creating session:', error)
    }
  }

  const restoreSession = async () => {
    if (!userId || isPageExcluded() || sessionDocRef.current) return

    const sessionId = sessionStorage.getItem('sessionDocRef')

    if (!sessionId) return

    const potentialSessionRef = doc(db, 'users', userId, 'sessions', sessionId)
    const sessionDoc = await getDoc(potentialSessionRef)

    if (sessionDoc.exists()) {
      const sessionData = sessionDoc.data()

      // If session hasn't ended, restore it
      if (!sessionData.endTime) {
        sessionDocRef.current = potentialSessionRef
        startTimeRef.current = Date.now()

        // Start the heartbeat interval
        startHeartbeat()
      } else {
        // If session ended, remove from storage
        sessionStorage.removeItem('sessionDocRef')
        sessionStorage.removeItem('lastActiveTime')
      }
    } else {
      // Doc no longer exists
      sessionStorage.removeItem('sessionDocRef')
      sessionStorage.removeItem('lastActiveTime')
    }
  }

  const incrementUserWebSessions = async () => {
    if (!userId) return

    const userDocRef = doc(db, 'users', userId)

    try {
      await updateDoc(userDocRef, {
        webSessions: increment(1),
      })
    } catch (error) {
      console.error('Error incrementing user webSessions:', error)
    }
  }

  const updateSession = async (timeSpent, type) => {
    if (!sessionDocRef.current || !userId) return

    const sessionRef = sessionDocRef.current

    try {
      await runTransaction(db, async transaction => {
        const sessionDoc = await transaction.get(sessionRef)

        if (!sessionDoc.exists()) {
          return
        }

        const sessionData = sessionDoc.data()
        const pageViews = sessionData.pageViews || []
        const lastPageView = pageViews[pageViews.length - 1]

        // Close out the last pageView if still open
        if (lastPageView && !lastPageView.endTime) {
          const endTime = Timestamp.now()

          if (type === 'end') {
            // Only set endTime without modifying timeSpent
            lastPageView.endTime = endTime
          } else {
            // For other types, update timeSpent

            lastPageView.endTime = endTime
            lastPageView.timeSpent += timeSpent

            // Increment user's totalActiveTime within the same transaction
            const userRef = doc(db, 'users', userId)

            transaction.update(userRef, {
              totalActiveTime: increment(timeSpent),
            })
          }
        }

        if (type === 'end') {
          sessionData.endTime = Timestamp.now()
        }

        // Recompute totalTime
        const newTotalTime = pageViews.reduce((acc, view) => acc + (view.timeSpent || 0), 0)

        transaction.update(sessionRef, {
          ...sessionData,
          endTime: type === 'end' ? Timestamp.now() : sessionData.endTime,
          pageViews,
          totalTime: newTotalTime,
        })
      })

      // If ending session, increment user’s web session count & remove ref
      if (type === 'end') {
        await incrementUserWebSessions()
        sessionDocRef.current = null
        sessionStorage.removeItem('sessionDocRef')

        // Clear the heartbeat interval
        clearHeartbeat()
      }
    } catch (error) {
      console.error('Error updating session:', error)
    }
  }

  const endSession = async () => {
    if (!sessionDocRef.current) return

    // Capture any residual active time
    await handleHeartbeat()

    // End the session without adding extra timeSpent
    await updateSession(0, 'end')

    // Clean up session references
    sessionDocRef.current = null
    sessionStorage.removeItem('sessionDocRef')
    sessionStorage.removeItem('lastActiveTime')

    // Clear the heartbeat interval
    clearHeartbeat()
  }

  // Function to start the heartbeat interval
  const startHeartbeat = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
    }

    intervalRef.current = setInterval(() => {
      handleHeartbeat()
    }, 60 * 1000) // 1 minute
  }

  // Function to clear the heartbeat interval
  const clearHeartbeat = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current)
      intervalRef.current = null
    }
  }

  // BroadcastChannel setup for cross-tab communication
  useEffect(() => {
    const handleBroadcastMessage = event => {
      if (event.data && event.data.type === 'LOGOUT') {
        endSession()
      }
    }

    if (sessionBroadcast) {
      sessionBroadcast.addEventListener('message', handleBroadcastMessage)
    }

    return () => {
      if (sessionBroadcast) {
        sessionBroadcast.removeEventListener('message', handleBroadcastMessage)
      }
    }
  }, [endSession])

  const handleHeartbeat = async () => {
    if (!userId || !startTimeRef.current || !sessionDocRef.current) return
    if (isSyncingRef.current) return

    isSyncingRef.current = true
    const sessionRef = sessionDocRef.current

    try {
      const sessionDoc = await getDoc(sessionRef)

      if (!sessionDoc.exists()) {
        return
      }

      // Calculate the time since last heartbeat
      const now = Date.now()
      let timeSpent = Math.floor((now - startTimeRef.current) / 1000)

      if (timeSpent <= 0) return

      const userRef = doc(db, 'users', userId)

      // Update just the current pageView's timeSpent in Firestore and increment totalActiveTime
      await runTransaction(db, async transaction => {
        const freshSessionDoc = await transaction.get(sessionRef)

        if (!freshSessionDoc.exists()) {
          return
        }

        const freshSessionData = freshSessionDoc.data()
        const pageViews = freshSessionData.pageViews || []
        const lastPageView = pageViews[pageViews.length - 1]

        if (lastPageView && !lastPageView.endTime) {
          lastPageView.timeSpent += timeSpent
          const newTotalTime = pageViews.reduce((acc, pv) => acc + (pv.timeSpent || 0), 0)

          transaction.update(sessionRef, { pageViews, totalTime: newTotalTime })

          // Increment user's totalActiveTime
          transaction.update(userRef, {
            totalActiveTime: increment(timeSpent),
          })
        }
      })

      // Reset startTime for next heartbeat
      startTimeRef.current = Date.now()
    } catch (error) {
      console.error('Error in handleHeartbeat:', error)
    } finally {
      isSyncingRef.current = false
    }
  }

  const handleOnActive = async () => {
    if (!userId || isPageExcluded()) return

    // If user had no startTime set, create a session and start tracking
    if (!startTimeRef.current) {
      await createSession()
    }
  }

  const handleOnIdle = async () => {
    if (!userId || isPageExcluded()) return

    if (startTimeRef.current) {
      await endSession()
      startTimeRef.current = null
    }
  }

  const handleBeforeUnload = () => {
    if (!userId || !sessionDocRef.current || isPageExcluded()) return

    if (startTimeRef.current) {
      const timeSpent = Math.floor((Date.now() - startTimeRef.current) / 1000)

      sessionStorage.setItem('lastActiveTime', timeSpent.toString())
    }

    const sessionId = sessionDocRef.current.id

    sessionStorage.setItem('sessionDocRef', sessionId)

    // Store session info in localStorage before unload
    const localStorageKey = getLocalStorageKey()
    const sessionInfo = {
      sessionId,
      lastActiveTime: sessionStorage.getItem('lastActiveTime') || '0',
      timestamp: Date.now(),
    }

    try {
      localStorage.setItem(localStorageKey, JSON.stringify(sessionInfo))
    } catch (error) {
      console.error('Error saving session info to localStorage:', error)
    }
  }

  const handlePageChange = async newPage => {
    if (!sessionDocRef.current || EXCLUDED_PAGES.includes(newPage)) return

    const sessionRef = sessionDocRef.current
    const userRef = doc(db, 'users', userId)

    try {
      await runTransaction(db, async transaction => {
        const sessionDoc = await transaction.get(sessionRef)
        const userDoc = await transaction.get(userRef)

        if (!sessionDoc.exists() || !userDoc.exists()) {
          throw 'Session or User document does not exist!'
        }

        const sessionData = sessionDoc.data()
        const pageViews = sessionData.pageViews || []
        const lastPageView = pageViews[pageViews.length - 1]

        let timeSpent = 0
        let lastTimeSpent = 0

        // Close out old page if still open
        if (lastPageView && !lastPageView.endTime) {
          const endTime = Timestamp.now()

          timeSpent = Math.floor((endTime.toDate() - lastPageView.startTime.toDate()) / 1000)

          lastTimeSpent = timeSpent - lastPageView?.timeSpent || 0

          lastPageView.endTime = endTime
          lastPageView.timeSpent = timeSpent
        }

        // Add a new page view
        pageViews.push({
          page: newPage,
          startTime: Timestamp.now(),
          endTime: null,
          timeSpent: 0,
        })

        const newTotalTime = pageViews.reduce((acc, pv) => acc + (pv.timeSpent || 0), 0)

        // Update session with new pageViews and totalTime
        transaction.update(sessionRef, {
          pageViews,
          totalTime: newTotalTime,
        })

        // Increment user's totalActiveTime
        if (lastTimeSpent > 0) {
          transaction.update(userRef, {
            totalActiveTime: increment(lastTimeSpent),
          })
        }
      })

      pageStartTimeRef.current = Date.now()
      previousPageRef.current = newPage
      startTimeRef.current = Date.now()
    } catch (error) {
      console.error('Error handling page change:', error)
    }
  }

  // Idle timer setup
  useIdleTimer({
    timeout: IDLE_TIMEOUT_SECONDS * 1000, // 10 minutes
    onIdle: handleOnIdle,
    onActive: handleOnActive,
    debounce: 500,
  })

  useEffect(() => {
    if (!userId || EXCLUDED_PAGES.includes(page)) return
    ;(async () => {
      // Attempt to restore a previous session
      await restoreSession()

      // Check localStorage for any stored session info and process it only if it's not a reload
      const navigationEntries = performance.getEntriesByType('navigation')
      const navType =
        (navigationEntries[0] && navigationEntries[0].type) || (performance.navigation && performance.navigation.type)

      const isReload = navType === 'reload' || navType === 1 // performance.navigation.type === 1 for reload in older browsers

      if (!isReload) {
        const localStorageKey = getLocalStorageKey()
        const storedSessionInfo = localStorage.getItem(localStorageKey)

        if (storedSessionInfo) {
          try {
            const sessionInfo = JSON.parse(storedSessionInfo)

            // End the session properly using the stored session ID and last active time
            const { sessionId, lastActiveTime, timestamp } = sessionInfo

            if (sessionId && lastActiveTime) {
              const sessionRef = doc(db, 'users', userId, 'sessions', sessionId)

              await runTransaction(db, async transaction => {
                const sessionDoc = await transaction.get(sessionRef)

                if (!sessionDoc.exists()) {
                  return
                }

                const sessionData = sessionDoc.data()
                const pageViews = sessionData.pageViews || []
                const lastPageView = pageViews[pageViews.length - 1]

                if (lastPageView && !lastPageView.endTime) {
                  lastPageView.endTime = Timestamp.fromDate(new Date(timestamp))
                  lastPageView.timeSpent += parseInt(lastActiveTime, 10)
                }

                // Update totalTime
                const newTotalTime = pageViews.reduce((acc, view) => acc + (view.timeSpent || 0), 0)

                transaction.update(sessionRef, {
                  pageViews,
                  totalTime: newTotalTime,
                  endTime: Timestamp.now(),
                })

                // Increment user's totalActiveTime
                const userRef = doc(db, 'users', userId)

                transaction.update(userRef, {
                  totalActiveTime: increment(parseInt(lastActiveTime, 10)),
                  webSessions: increment(1),
                })
              })
            }

            // Remove the processed session info from localStorage
            localStorage.removeItem(localStorageKey)
          } catch (error) {
            console.error('Error processing stored session info from localStorage:', error)
          }
        }
      } else {
        // Since it's a reload, remove any existing session info from localStorage to prevent false termination
        const localStorageKey = getLocalStorageKey()

        localStorage.removeItem(localStorageKey)
      }

      if (!sessionDocRef.current) {
        await createSession()
      }

      // Listen for tab close/unload
      window.addEventListener('beforeunload', handleBeforeUnload)
    })()

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload)
    }
  }, [userId, assignmentId, page])

  useEffect(() => {
    if (previousPageRef.current !== page) {
      handlePageChange(page)
    }
  }, [page])

  return { endSession }
}
